#root > .container {
  height: 100vh;
  width: fit-content;
  overflow: hidden;
}
#root > .container > .paper {
  height: 100vh;
}
@media screen and (width >= 200px) {
  #root > .container {
    width: 100%;
  }

  #root > .container > .paper {
    height: auto;
  }
}

.subcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.circular-progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  width: 150px;
}

@media screen and (width < 200px) {
  .launch-button {
    display: none;
  }
}

.select-unit {
  margin: 10px;
}

.button-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1;
  width: 200px;
}

.pauseTimer {
  color: red;
}

.pastTasksBox {
  margin-top: 20px;
}

.timer-container {
  display: flex;
  align-items: center;
  padding: 5px;
}

.button-container {
  margin-right: 5px;
}

.timers-wrapper {
  display: flex;
  flex-direction: column;
}

.timer-wrapper {
  display: flex;
  align-items: center;
}
